import { useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { UserAFCCActivate, UserAFCCIndex, UserAFCCLicence, UserGetMe, UserIndex, UserShow, UserUpdateMe } from "../../lib/backend/user"
import { Skeleton } from "../../components/ui/skeleton"
import { WeddingCreate, WeddingIndex, WeddingShow, WeddingUpdate } from "../../lib/backend/wedding"
import { Wedding } from "../../../../backend/models/wedding"
import { Button } from "../../components/ui/button"
import { DataTable } from "../../components/TemplateTable"
import { Rows, X } from "lucide-react"

import { ArrowUpDown, MoreHorizontal } from "lucide-react"

import {
	Dialog,
	DialogClose,
	DialogFooter,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../components/ui/dialog"

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "../../components/ui/alert-dialog"

import { dateToNiceString } from "../../lib/utils"
import { Label } from "../../components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { User } from "../../../../backend/models/user"
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react"
import { Invoice } from "../../../../backend/models/invoice"
import { InactiveMessage } from "../../components/InactiveMessage"
import { GetSession } from "../../lib/backend/auth"
import { CellRendererComponent } from "@ag-grid-community/core/dist/types/src/components/framework/componentTypes"

type UserTableRow = Pick<User, "id" | "first_name" | "last_name" | "email" | "status">

export default function AfccPage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const gridRef = useRef<AgGridReact>(null);
	const [rows, setRows] = useState<UserTableRow[]>([])
	const [user, setUser] = useState<{ id: number, manager: User["manager"], status: User["status"] }>({ id: 0, manager: "", status: "A" })
	const [licenceCount, setLicenceCount] = useState<number>(0)
	const [activeCount, setActiveCount] = useState<number>(0)

	const navigate = useNavigate();

	useEffect(() => {
		getFilesData()
	}, [])

	async function getFilesData() {
		//
		const usersResponse = await UserAFCCIndex()
		if (usersResponse.ok) {
			//
			let usersBody = await usersResponse.json()
			UpdateRows(usersBody)
			//
			const response = await GetSession()
			if (!response.ok) {
				navigate("/login")
			} else {
				const body = await response.json()
				const meResponse = await UserShow(body.session.id)
				if (meResponse.ok) {
					const meBody = await meResponse.json()
					setUser(meBody)
					//
					const licenceCountResponse = await UserAFCCLicence()
					if (licenceCountResponse.ok) {
						const licenceCountBody = await licenceCountResponse.json()
						//
						setLicenceCount(licenceCountBody.licence_count)
						//
					} else {
						navigate("/login")
					}
					//
					setInitialLoading(false)
				} else {
					navigate("/login")
				}
			}
			//
		} else {
			//
			navigate("/login")
			//
		}
		//
	}

	async function UpdateRows(newRows: UserTableRow[]) {
		newRows = newRows.map((user: UserTableRow) => {
			if (user.status === "A") {
				//@ts-ignore
				user.status = "Active"
			} else {
				//@ts-ignore
				user.status = "Inactive"
			}
			return user
		})
		setRows(newRows)
		//
		setActiveCount(newRows.filter((row: any) => row.status === "Active" && row.afcc_confirmed === "Yes").length)
		//
	}

	const activateButton = (params: CustomCellRendererProps) => {

		const activateUser = async () => {
			//
			if (params.data.status !== "Active") {
				//
				const response = await UserAFCCActivate(params.data.id)
				if (response.ok) {
					//
					const body = await response.json()
					UpdateRows(body)
					//
				} else {
					navigate("/login")
				}
				//
			}
			//
		}

		return (
			<button
				onClick={activateUser}
				hidden={params.data.status === "Active"}
				className="bg-green-500 text-white cursor-pointer w-full rounded-lg"
			>
				Activate
			</button>
		)
	}

	return (
		<div className="px-2 mt-4">
			<div>
				<div className="text-2xl font-semibold mb-4 flex justify-between">
					<h1 className="text-3xl font-bold">
						Manage AFCC Users
					</h1>
				</div>
				<div className="w-20 h-20 mt-8" hidden={!initialLoading}>
					<svg aria-hidden="true" className="w-full text-left h-full m-0 mt-6 mb-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
				<div hidden={initialLoading} className="mt-8 w-full">
					<div className="my-2 text-lg">
						<span className="font-bold">Available Licences:</span> {licenceCount - activeCount}
					</div>
					<div className="mt-2 mb-8 text-lg">
						<span className="font-bold">Total Licences:</span> {licenceCount}
					</div>
					<InactiveMessage />
					<DataTable
						gridRef={gridRef}
						// sortChanged={sortChanged}
						columns={[
							{ field: "first_name", headerName: "First Name" },
							{ field: "last_name", headerName: "Last Name" },
							{ field: "email", headerName: "Email" },
							{ field: "status", headerName: "Status" },
							{ field: "activate_button", headerName: "Activate", cellRenderer: activateButton },
						]}
						data={rows}
						columnVisibility={{
							first_name: true,
							last_name: true,
							email: false,
							status: false,
							activate_button: false,
						}}
						onNewClick={() => { }}
						onRowClick={() => { }}
						createButtonText="Create User"
						underComponent={<div></div>}
						hideCreateButton={true}
					/>
				</div>
			</div>
		</div>
	)
}

// export function ConfirmDialog(props: { openConfirm: boolean, create: boolean, getResult: any }) {
// 	return (
// 		<AlertDialog
// 			defaultOpen={false}
// 			open={props.openConfirm}
// 		>
// 			<AlertDialogContent>
// 				<AlertDialogHeader>
// 					<AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
// 					<AlertDialogDescription className="text-lg my-8">
// 						You have made changes to this wedding.
// 						<br />
// 						Are you sure you want to close without saving and lose all your changes?
// 					</AlertDialogDescription>
// 				</AlertDialogHeader>
// 				<AlertDialogFooter>
// 					<AlertDialogCancel
// 						className="text-lg bg-destructive hover:bg-red-400 text-white hover:text-white"
// 						onClick={() => {
// 							props.getResult(true)
// 						}}
// 					>
// 						Yes. Remove my changes.
// 					</AlertDialogCancel>
// 					<AlertDialogAction
// 						className="text-lg"
// 						onClick={() => {
// 							props.getResult(false)
// 						}}
// 					>
// 						No. Take me back to the wedding.
// 					</AlertDialogAction>
// 				</AlertDialogFooter>
// 			</AlertDialogContent>
// 		</AlertDialog>
// 	)
// }
