import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { useCallback, useState } from 'react';
import { Button } from './ui/button';
import { create } from 'domain';
import { CellValueChangedEvent } from '@ag-grid-community/core';

interface DataTableProps {
	columns: any
	data: any
	columnVisibility: any
	onNewClick: any
	onRowClick: any
	createButtonText: string
	underComponent?: any
	gridRef?: any
	sortChanged?: any
	disableCreateButton?: boolean
	hideCreateButton?: boolean
}

export function DataTable(props: DataTableProps) {

	const defaultColDef = {
		flex: 1,
	}

	var athleteFilterParams = {
		filterOptions: ["contains"],
		debounceMs: 0,
		maxNumConditions: 4,
	};

	function stringComparator(string1: string, string2: string) {
		//
		if (typeof string1 === "number") {
			//@ts-ignore
			if (string1 > string2) {
				return 1
				//@ts-ignore
			} else if (string2 > string1) {
				return -1
			} else {
				return 0
			}
		}
		//
		string1.toUpperCase()
		string2.toUpperCase()
		//
		if (string1 === null && string2 === null) {
			return 0
		}
		if (string1 === null) {
			return -1
		}
		if (string2 === null) {
			return 1
		}
		return string1.localeCompare(string2)
	}

	function dateComparator(date1: string, date2: string) {
		//
		const date1Number = monthToComparableNumber(date1)
		const date2Number = monthToComparableNumber(date2)
		//
		if (date1Number === null && date2Number === null) {
			return 0
		}
		if (date1Number === null) {
			return -1
		}
		if (date2Number === null) {
			return 1
		}
		return date1Number - date2Number
	}

	// eg 29/08/2004 gets converted to 20040829
	function monthToComparableNumber(date: string) {
		if (date === undefined || date === null || date.length === 0) {
			return null;
		}
		const dateAsDate = new Date(date)
		const yearNumber = Number.parseInt(dateAsDate.getFullYear().toString());
		const monthNumber = dateAsDate.getMonth() - 1
		const dayNumber = Number.parseInt(dateAsDate.getDate().toString())
		return yearNumber * 10000 + monthNumber * 100 + dayNumber;
	}

	const columns = props.columns.map((column: any) => {
		return {
			...column,
			filter: 'agTextColumnFilter',
			comparator: column.type === "date" ? dateComparator : stringComparator,
			type: undefined,
			floatingFilter: true,
			filterParams: athleteFilterParams,
		}
	})

	return (
		<div>
			{props.hideCreateButton === undefined || props.hideCreateButton === false ? <Button
				className="mb-6 text-md p-2 px-4 shadow"
				variant={"default"}
				disabled={props.disableCreateButton}
				onClick={() => {
					props.onNewClick()
				}}
			>
				{props.createButtonText}
			</Button> : null}
			{props.underComponent ? props.underComponent : null}
			{/* wrapping container with theme & size */}
			<div
				className="ag-theme-quartz" // applying the grid theme
				style={{ height: 474 }} // the grid will fill the size of the parent container
			>
				<AgGridReact
					ref={props.gridRef ? props.gridRef : null}
					rowData={props.data}
					//@ts-ignore
					columnDefs={columns}
					defaultColDef={defaultColDef}
					onRowClicked={(event) => {
						props.onRowClick(event.data.id)
					}}
					rowSelection={"single"}
					onSortChanged={(event) => { if (props.sortChanged) props.sortChanged(event) }}
				/>
			</div>
		</div >
	)

}























// import { useState } from "react"

// import {
// 	ColumnDef,
// 	flexRender,
// 	getCoreRowModel,
// 	getPaginationRowModel,
// 	SortingState,
// 	getSortedRowModel,
// 	useReactTable,
// 	ColumnFiltersState,
// 	getFilteredRowModel,
// 	RowSelectionState,
// } from "@tanstack/react-table"

// import {
// 	Table,
// 	TableBody,
// 	TableCell,
// 	TableHead,
// 	TableHeader,
// 	TableRow,
// } from "./ui/table"

// import { Input } from "./ui/input"
// import { Button } from "./ui/button"

// interface DataTableProps<TData, TValue> {
// 	columns: ColumnDef<TData, TValue>[]
// 	data: TData[]
// 	columnVisibility: any
// 	onNewClick: any
// 	onRowClick: any
// }

// export function DataTable<TData, TValue>({
// 	columns,
// 	data,
// 	columnVisibility,
// 	onNewClick,
// 	onRowClick
// }: DataTableProps<TData, TValue>) {
// 	//
// 	const [sorting, setSorting] = useState<SortingState>([])
// 	//
// 	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
// 	//
// 	const table = useReactTable({
// 		data,
// 		columns,
// 		enableRowSelection: true,
// 		getCoreRowModel: getCoreRowModel(),
// 		// getPaginationRowModel: getPaginationRowModel(),
// 		onSortingChange: setSorting,
// 		getSortedRowModel: getSortedRowModel(),
// 		onColumnFiltersChange: setColumnFilters,
// 		getFilteredRowModel: getFilteredRowModel(),
// 		state: {
// 			sorting: sorting,
// 			columnFilters: columnFilters,
// 		},
// 		initialState: {
// 			columnVisibility: columnVisibility
// 		}
// 	})

// 	let weddingNameInput = <div className="flex items-center py-2">
// 		<Input
// 			placeholder="Filter"
// 			value={(table.getColumn("general_wedding_name")?.getFilterValue() as string) ?? ""}
// 			onChange={(event) =>
// 				table.getColumn("general_wedding_name")?.setFilterValue(event.target.value)
// 			}
// 			className=""
// 		/>
// 	</div>
// 	let weddingDateInput = <div className="flex items-center py-2">
// 		<Input
// 			placeholder="Filter"
// 			value={(table.getColumn("wedding_date_string")?.getFilterValue() as string) ?? ""}
// 			onChange={(event) =>
// 				table.getColumn("wedding_date_string")?.setFilterValue(event.target.value)
// 			}
// 			className=""
// 		/>
// 	</div>

// 	const totalRowCount = data.length
// 	let pageChoices = [10, 20, 30, 40, 50, 100]
// 	pageChoices = pageChoices.filter((choice) => choice <= totalRowCount)
// 	if (pageChoices.includes(totalRowCount) === false) {
// 		pageChoices.push(totalRowCount)
// 	}

// 	return (
// 		<div>
// 			<Button
// 				className="mb-6 text-md p-2 px-4 shadow"
// 				variant={"default"}
// 				onClick={() => {
// 					onNewClick()
// 				}}
// 			>
// 				Create Wedding
// 			</Button>
// 			<div
// 				className="rounded-md border text-left text-card-foreground bg-white block h-96 overflow-y-scroll"
// 			>
// 				<Table>
// 					<TableHeader className="sticky top-0 h-20">
// 						{table.getHeaderGroups().map((headerGroup) => (
// 							<TableRow key={headerGroup.id} className="text-left hover:bg-white">
// 								{headerGroup.headers.map((header) => {
// 									return (
// 										<TableHead
// 											key={header.id}
// 										>
// 											{header.isPlaceholder
// 												? null
// 												: flexRender(
// 													header.column.columnDef.header,
// 													header.getContext()
// 												)}
// 											{header.id === "general_wedding_name" ? weddingNameInput : weddingDateInput}
// 										</TableHead>
// 									)
// 								})}
// 							</TableRow>
// 						))}
// 					</TableHeader>
// 					<TableBody className="h-40 overflow-y-auto">
// 						{table.getPrePaginationRowModel().rows?.length ? (
// 							table.getPrePaginationRowModel().rows.map((row) => (
// 								<TableRow
// 									key={row.id}
// 									data-state={row.getIsSelected() && "selected"}
// 									className="cursor-pointer hover:bg-blue-600 hover:text-white"
// 									onClick={() => {
// 										///@ts-ignore
// 										onRowClick(row.original.id)
// 									}}
// 								>
// 									{row.getVisibleCells().map((cell) => (
// 										<TableCell key={cell.id} className="my-0 py-0 h-10">
// 											{flexRender(cell.column.columnDef.cell, cell.getContext())}
// 										</TableCell>
// 									))}
// 								</TableRow>
// 							))
// 						) : (
// 							<TableRow>
// 								<TableCell colSpan={columns.length} className="h-24 text-center">
// 									No results.
// 								</TableCell>
// 							</TableRow>
// 						)}
// 					</TableBody>
// 				</Table>
// 			</div>
// 			{/* <div className={`grid grid-cols-4 divide-x-2 mt-8`}>
// 				<div className="flex w-full justify-center bg-slate-400 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
//     focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 rounded-s-lg">
// 					Rows:
// 				</div>
// 				<div className="flex w-full justify-center bg-slate-400 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
//     focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
//     focus-visible:outline-slate-500">
// 					{table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} - {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + table.getRowModel().rows.length} of {data.length}
// 				</div>
// 				<div className="flex w-full justify-center bg-slate-400 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
//     focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
// 					Pages:
// 				</div>
// 				<div className="w-full">
// 					<select
// 						value={table.getState().pagination.pageSize}
// 						onChange={e => {
// 							table.setPageSize(Number(e.target.value))
// 						}}
// 						className="flex w-full justify-center bg-slate-400 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm
// 		cursor-pointer rounded-e-lg outline-none focus-visible:outline-none rounded-r-lg border-gray-700 -mr-1"
// 					>
// 						{pageChoices.map(pageSize => (
// 							<option key={pageSize} value={pageSize} className="text-center justify-center">
// 								{pageSize}
// 							</option>
// 						))}
// 					</select>
// 				</div>
// 			</div>
// 			<div className={`grid grid-cols-4 divide-x-2 mt-4`}>
// 				<button onClick={() => table.setPageIndex(0)}
// 					className="flex w-full justify-center bg-primary px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
//     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
//     focus-visible:outline-indigo-500 cursor-pointer rounded-s-lg"
// 				>
// 					First page
// 				</button>
// 				<button disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}
// 					className="flex w-full justify-center bg-primary px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
//     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
//     focus-visible:outline-indigo-500 cursor-pointer"
// 				>
// 					Previous page
// 				</button>
// 				<button disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}
// 					className="flex w-full justify-center bg-primary px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
//     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
//     focus-visible:outline-indigo-500 cursor-pointer"
// 				>
// 					Next page
// 				</button>
// 				<button onClick={() => table.setPageIndex(table.getPageCount() - 1)}
// 					className="flex w-full justify-center bg-primary px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm
//     hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
//     focus-visible:outline-indigo-500 cursor-pointer rounded-e-lg"
// 				>
// 					Last page
// 				</button>
// 			</div> */}
// 		</div>
// 	)
// }
